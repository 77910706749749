:host {

    border-radius: 8px;
    padding: 1.2rem;
    background-color: var(--surface--color--primary--light);
    color: var(--color--dark-text);
    text-align: center;
}



