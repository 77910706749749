:host {

    line-height: .9;
    display: inline-flex;    
    align-items: center;      
      


    border-radius: 4px;
    border: 1px solid #B3B3B3;
    padding: .5rem 0.8rem;
    /* text-align: center; */
    width: fit-content;


}
:host([rounded="true"]), :host([rounded=""]) {
    border-radius: 20px;
}

:host([primary="true"]), :host([primary=""]) {
    border: none;
    background-color: var(--surface--color--primary--dark);
    color: white;
  

}


